import React from 'react'

import BarChart from '../Chart/BarChart'

const Dashboard = () => {
  return (
    <div>
      <h5 style={{color:'black'}}>Dashboard</h5>
      <BarChart />
    </div>
  )
}

export default Dashboard
